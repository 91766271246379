import React,{useState} from 'react';
//import logo from './logo.svg';
import './App.css';

import i18n from "i18next";
import { useTranslation, initReactI18next, Trans } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    

    resources: {
      en: {
        translation: {
          "hello@mafate.menu": "hello@mafate.menu",
          "Welcome to React": "Welcome to React and react-i18next",
          "Pricing": "Pricing",
          "Contact Us": "Contact Us",
          "Schedule Appointments": "Schedule Appointments",
          "Free Online Appointment": "Free Online Appointment",
          "Home": "Home",
          "Services": "Services",
          "Our Services": "Our Services",
          "Follow Us": "Follow Us",
          "On Facebook": "on Facebook",
          "On Instagram": "on Instagram",
          //"Mobile Application": "",
          //"Website": "Website",
          "We're building your": "We're building your",
          "Choose": "Choose <bold>efficiency</bold> and <bold>quickness</bold> !",
          "ServicesBlockLine1": "Graphic Studio",
          "Domain Name": "Domain Name",
          "We are booking your domain": "We are booking your domain",
          "Email Account": "Email Account",
          "Your professional emails like xyz@mycompany.com": "Your professional emails like xyz@mycompany.com",
          "Mobile Application": "Mobile Application",
          "Mobile_Application_PLURAL": "Mobile Applications",
          "Your iOS & Android mobile application": "Your iOS & Android mobile application",
          "Website": "Website",
          "Your personnalised website": "Your personnalised website",
          "Your responsive website": "Your responsive website",
          "Your mobile app on": "Your mobile app on",
          "WebsiteOnlyInEnglish": " Website",
          "from": "from",
          "access included in the monthly subscription": " access included in the monthly subscription",
          "multilingual_line": "<strong>Multilingual</strong>",
          "10 hours of on-demand development included": "10 hours of <strong>on-demand development</strong> included",
          "PricingBlockTitle": "Transparent Pricing",
          "PricingBlockLine1": "We value transparency & simplicity.",
          "PricingBlockLine2": "",
          "ContactBlockLine1": "We will answer in the next 24hours",
          "ContactBlockLine2": "Any questions, contact-us",
          "Personnalisation_template": "Design created from our <strong>templates</strong> & adjusted with your colors",
          "Personnalisation_premium": "We will integrate your graphical charter",
          "Name": "Name",
          "Company": "Company",
          "Email": "Email",
          "Phone": "Phone",
          "Your Message": "Your Message",
          "Your details": "Your details",
          "Booking an online appointment": "Booking an online appointment",
          "Send": "Send",
          "Subscribe": "Subscribe",
          "to our newsletter": "to our newsletter",
          "btnSubscribe": "Subscribe",
          "Terms of Use": "Terms of Use",
          "Privacy Policy": "Privacy Policy",
          "month": "month",
          "then": "then",
          "Technology": "Technology",
          "Hosting": "Hosting",
          "included": "included",
          "email account": "email account",
          "Cookies management": "Cookies management",
          "GDPR Compliant": "GDPR Compliant",
          "User authentification": "User authentification",
          "Administration Panel": "Administration Panel",
          "Easy to use": "Easy-to-use ",
          "simple d'utilisation": "",
          "Up to": "Up to",
          "Currency": "Currency",
          "modules_liste_onpricingblock": "presentation, blog/news, prestations, FAQ, customer reviews, menu, concours, agenda, photo gallery, videos, appointment, booking, lexicon, contact/maps",
          "accroche_bas": "<strong>Quickness</strong> & <strong>efficiency</strong> fairly-priced</strong> !", 
          "We're taking care of": "We're taking care of",
          "your Social Network": "your Social Network",
          "your Instagram Account": "your Instagram account",
          "your Twitter Account": "your Twitter account",
          "your Facebook Page": "your Facebook Page",
          "your LinkedIn Page": "your LinkedIn Page",
          "Complete package": "Complete package",
          "Publicité Facebook & Instagram": "Facebook & Instagram Advertisement",
          "Nous optimisons vos publicités": "We will optimize your Ads",
          "Nous créons vos Audiences publicitaires": "We will create & optimize your Facebook Ads Audiences",
          "Nous créons et planifions vos posts et story": "We are creating and scheduling your posts",
          "Nous créons vos visuels sur la base de template ou sur-mesure": "We are design your Post/Story from template, but also on-demand",
          "L'essentiel":"Essentials",
          "Formule Complète":"Performance",
          "Forfait Pro": "Professional",
          "Forfait PREMIUM": "PREMIUM",
          "Plateformes": "Platforms",
          "Sans-engagement": "Flexible",
          "Engagement annuel": "1 year contract",
          "ENGAGEMENT ANNUEL": "1 YEAR CONTRACT",
          "Votre menu sur": "Votre menu sur",
          "Carte de visite": "Business Card",
          "Cartes de visite": "Business Card",
          "Logo": "Logo",
          "Flyers": "Flyers",
          "clés en main": "clés en main",
          "Site Web": "Site Web",
          "Application Mobile": "Application Mobile",
          "Authentification": "Authentification",
          "Module Membres": "Module Membres",
          "Forums de discussions": "Forums de discussions",
          "Sondages": "Sondages",
          "Agenda": "Agenda",
          "Concours": "Concours"
        }
      },fr: {
        translation: {
          "hello@mafate.menu": "bonjour@mafate.menu",
          "Welcome to React": "Bienvenue to React and react-i18next",
          "Pricing": "Tarifs",
          "Contact Us": "Nous Contacter",
          "Schedule Appointments": "Prendre RDV",
          "Free Online Appointment": "Planifier un RDV en ligne",
          "Home": "Accueil",
          "Services": "Services",
          "Our Services": "Nos Services",
          "Follow Us": "Suivez-nous",
          "On Facebook": "sur Facebook",
          "On Instagram": "sur Instagram",
          //"Mobile Application": "Application Mobile",
          //"Website": "Site Internet",
          "We're building your": "Nous réalisons votre",
          "Choose": "Choisissez l'<bold>efficience</bold> et la <bold>rapidité</bold>, au juste prix !",
          "ServicesBlockLine1": "Votre agence de création graphique",
          "Domain Name": "Nom de domaine",
          "Email Account": "Comptes Email Pro",
          "Your professional emails like xyz@mycompany.com": "Vos emails du type xyz@votreentreprise.com",
          "We are booking your domain": "On réserve votre nom de domaine",
          "Mobile Application": "Application Mobile",
          "Mobile Application PLURAL": "Applications Mobiles",
          "Your iOS & Android mobile application": "Votre application mobile disponible sur iOS et Android",
          "Website": "Site Internet",
          "Your personnalised website": "Votre site internet personnalisé",
          "Your responsive website": "Votre site internet responsive",
          "Your mobile app on": "Votre application mobile",
          "WebsiteOnlyInEnglish": " Website",
          "from": "dès",
          "access included in the monthly subscription": " (accès) inclus dans l'abonnement mensuel",
          "multilingual_line": "<strong>Multilingue</strong>",
          "10 hours of on-demand development included": "10 heures de <strong>développement sur-mesure</strong> inclus",
          "PricingBlockTitle": "Une tarification simple et transparente",
          "PricingBlockLine1": "Chez mafate.menu la transparence et la simplicité sont des valeurs fondamentales.",
          "PricingBlockLine2": "Nos tarifs sont en libre accès, vous pouvez savoir dès à présent combien vous coûtera notre prestation.",
          "ContactBlockLine1": "Réponse sous 24h",
          "ContactBlockLine2": "Vous avez des questions, contactez-nous dès à présent.",
          "Personnalisation_template": "Visuels créés sur la base de nos <strong>templates</strong> & ajustez à vos couleurs",
          "Personnalisation_premium": "Visuels créés sur-mesure pour une adaptation parfaite à votre <strong>charte graphique</strong>",
          "Name": "Nom & Prénom",
          "Company": "Société",
          "Email": "Email",
          "Phone": "Téléphone",
          "Your Message": "Votre message",
          "Your details": "Vos informations",
          "Booking an online appointment": "Prendre rendez-vous en ligne",
          "Send": "Send",
          "Subscribe": "Inscrivez-vous",
          "to our newsletter": "à notre newsletter",
          "btnSubscribe": "Inscription",
          "Terms of Use": "CGU",
          "Privacy Policy": "Politique de confidentialité",
          "month": "mois",
          "then": "puis",
          "Technology": "Technologie",
          "Hosting": "Hébergement",
          "included": "inclus",
          "email account": "compte email",
          "Cookies management": "Gestion des cookies",
          "GDPR Compliant": "conforme RGPD",
          "User authentification": "Connexion Utilisateur",
          "Administration Panel": "Panneau d'administration",
          "Easy to use": "",
          "simple d'utilisation": " simple d'utilisation",
          "Up to": "Jusqu'à",
          "Currency": "Devise",
          "modules_liste_onpricingblock": "présentation, blog, prestations, FAQ, avis client, menu, concours, agenda, photos, vidéos, prise de rendez-vous, réservations, lexique, contacts/plans",
          "accroche_bas": "<strong>Rapidité</strong> et <strong>efficience</strong> au <strong>Juste Prix</strong> !",
          "We're taking care of": "On s'occupe de",
          "your Social Network": "vos Réseaux Sociaux",
          "your Instagram Account": "votre compte Instagram",
          "your Twitter Account": "votre compte Twitter",
          "your Facebook Page": "votre page Facebook",
          "your LinkedIn Page": "votre page LinkedIn",
          "Complete package": "Package complet",
          "Publicité Facebook & Instagram": "Publicité Facebook & Instagram",
          "Nous optimisons vos publicités": "Nous optimisons vos publicités",
          "Nous créons vos Audiences publicitaires": "Nous créons vos Audiences publicitaires",
          "Nous créons et planifions vos posts et story": "Nous créons et planifions vos posts et story",
          "Nous créons vos visuels sur la base de template ou sur-mesure": "Nous créons vos visuels sur la base de template ou sur-mesure",
          "L'essentiel":"L'essentiel",
          "Formule Complète":"Complete",
          "Forfait Pro": "Forfait Pro",
          "Forfait PREMIUM": "Forfait PREMIUM",
          "Plateformes": "Plateformes",
          "Sans-engagement": "Sans-engagement",
          "Engagement annuel": "Engagement annuel",
          "ENGAGEMENT ANNUEL": "ENGAGEMENT ANNUEL",
          "Votre menu sur": "Your menu on",
          "Carte de visite": "Carte de visite",
          "Cartes de visite": "Cartes de visite",
          "Logo": "Logo",
          "Flyers": "Flyers",
          "clés en main": "clés en main",
          "Site Web": "Website",
          "Application Mobile": "Mobile Application",
          "Authentification": "Authentification",
          "Module Membres": "User module",
          "Forums de discussions": "Forums de discussions",
          "Sondages": "Sondages",
          "Agenda": "Events",
          "Concours": "Contests"
        }
      }
    },
    lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

function App() {

  const { t } = useTranslation();

  const [language, setLanguage] = useState('fr');
  const [currency, setCurrency] = useState('€');
  const [prices, setPrices] = useState({
        professional: {
          sms: {
            oneshot: 490,
            monthly: 0
          },
          email:  {
            oneshot: 150,
            monthly: 0
          },
          postal:  {
            oneshot: 250,
            monthly: 0
          },
          flyer: {
            regular: 129
          },
          businesscard: {
            regular: 99
          },
          logo: {
            regular: 490
          },
        },
        premium: {
          sms: {
            oneshot: 990,
            monthly: 0
          },
          email:  {
            oneshot: 290,
            monthly: 0
          },
          postal:  {
            oneshot: 590,
            monthly: 0
          },
          flyer: {
            regular: 249
          },
          businesscard: {
            regular: 229
          },
          logo: {
            regular: 990
          },
        }
      });




  const handleOnclick=(e)=>{
    e.preventDefault();
    let lang = e.target.getAttribute('data-lang');
    setLanguage(lang);
    i18n.changeLanguage(lang);
    if (lang==='fr'){
      setCurrency('€');
      changeCurrency('€');
    }
  }
 
  const handleCurrencyOnclick=(e)=>{
    e.preventDefault();
    let currency = e.target.getAttribute('data-currency');
    setCurrency(currency);
    changeCurrency(currency);
  }


/*
Site Pro : 2490€ / 2790$ / 2090 £
Site Premium : 4990€ / 5590$ / 4290£

Appli Pro : inchangé
Appli Premium : 6990€ / 7890$ / 5990£
*/
  const changeCurrency=(currency_target)=>{
    console.log('changeCurrency => '+currency_target);
    if (currency_target==='€'){
      setPrices({
        professional: {
          sms: {
            oneshot: 0.10,
            monthly: 0
          },
          email:  {
            oneshot: 0.05,
            monthly: 0
          },
          postal:  {
            oneshot: 2.5,
            monthly: 0
          },
          flyer: {
            regular: 129
          },
          businesscard: {
            regular: 99
          },
          logo: {
            regular: 490
          },
        },
        premium: {
          sms: {
            oneshot: 0.30,
            monthly: 0
          },
          email:  {
            oneshot: 0.25,
            monthly: 0
          },
          postal:  {
            oneshot: 3.5,
            monthly: 0
          },
          flyer: {
            regular: 249
          },
          businesscard: {
            regular: 229
          },
          logo: {
            regular: 990
          },
        }
      });
    }else if (currency_target==='$'){
      setPrices({
        professional: {
          sms: {
            oneshot: 0.10,
            monthly: 0
          },
          email:  {
            oneshot: 0.05,
            monthly: 0
          },
          postal:  {
            oneshot: 2.5,
            monthly: 0
          },
          flyer: {
            regular: 129
          },
          businesscard: {
            regular: 99
          },
          logo: {
            regular: 490
          },
        },
        premium: {
          sms: {
            oneshot: 0.30,
            monthly: 0
          },
          email:  {
            oneshot: 0.25,
            monthly: 0
          },
          postal:  {
            oneshot: 3.5,
            monthly: 0
          },
          flyer: {
            regular: 129
          },
          businesscard: {
            regular: 99
          },
          logo: {
            regular: 490
          },
        }
      });
    }else if (currency_target==='£'){
      setPrices({
        professional: {
          sms: {
            oneshot: 0.10,
            monthly: 0
          },
          email:  {
            oneshot: 0.05,
            monthly: 0
          },
          postal:  {
            oneshot: 2.5,
            monthly: 0
          },
          flyer: {
            regular: 129
          },
          businesscard: {
            regular: 99
          },
          logo: {
            regular: 490
          },
        },
        premium: {
          sms: {
            oneshot: 0.30,
            monthly: 0
          },
          email:  {
            oneshot: 0.25,
            monthly: 0
          },
          postal:  {
            oneshot: 3.5,
            monthly: 0
          },
          flyer: {
            regular: 129
          },
          businesscard: {
            regular: 99
          },
          logo: {
            regular: 490
          },
        }
      });
    }
    
  }




  function CurrentLang() {

    let imgSrc = 'demos/seo/images/flags/' + language+'.png?';
    return (
      <>
      <a href="#"><img src={imgSrc} alt="Lang" />{language}</a>
      </>
    );
    
  };

  function CurrentCurrency() {

    return (
      <>
      <a href="#">{t('Currency')} : {currency}</a>
      </>
    );
    
  };

  function SliderBlock() {

    setTimeout(() => {
      window.SEMICOLON.widget.textRotator();
    }, 100);
    return (
      <>
      <div className="slider_fr">
        <h2 className="text-rotater mb-2" data-rotate="fadeIn" data-speed="3500">{t('Votre menu sur')} <span className="t-rotate">QRcode,{t('Application Mobile')},{t('Site Web')},Telegram,Whatsapp</span></h2>
        <h3 className="text-rotater mb-2" data-separator="," data-rotate="fadeIn" data-speed="3500">- <span className="t-rotate" style={{color: 'inherit'}}>{t('Authentification')},{t('Module Membres')},{t('Forums de discussions')},{t('Sondages')},{t('Agenda')},{t('Concours')}</span></h3>
        <p>
        <Trans
          i18nKey="Choose" // optional -> fallbacks to defaults if not provided
          defaults="..." // optional defaultValue
          values={{ arg1: '...',arg2: '...'}}
          components={{ italic: <i />, bold: <strong /> }}
        />
        </p>
      </div>
      </>
    );
    
  };


  return (
    <>
  <div id="wrapper" className="clearfix">

    <div id="top-bar" className="transparent-topbar">
      <div className="container clearfix">

        <div className="row justify-content-between">
          <div className="col-12 col-md-auto">

            <div className="top-links">
              <ul className="top-links-container">
               <li className="top-links-item"><CurrentLang />
                  <ul className="top-links-sub-menu">
                    <li className="top-links-item"><a data-lang="en" onClick={handleOnclick}><img src="demos/seo/images/flags/eng.png" alt="Lang" />EN</a></li>
                    <li className="top-links-item"><a data-lang="fr" onClick={handleOnclick}><img src="demos/seo/images/flags/fre.png" alt="Lang" />FR</a></li>
                  </ul>
                </li>
                <li className="top-links-item"><CurrentCurrency />
                  <ul className="top-links-sub-menu">
                    <li className="top-links-item"><a data-currency="£" onClick={handleCurrencyOnclick}>£</a></li>
                    <li className="top-links-item"><a data-currency="€" onClick={handleCurrencyOnclick}>€</a></li>
                    <li className="top-links-item"><a data-currency="$" onClick={handleCurrencyOnclick}>$</a></li>
                  </ul>
                </li>
                {/*<li className="top-links-item"><a href="#">Mafate Groupe</a>
                  <ul className="top-links-sub-menu">
                    <li className="top-links-item"><a href="https://www.mafate.co">Website</a></li>
                    <li className="top-links-item"><a href="https://www.mafate.co">Mobile App</a></li>
                    <li className="top-links-item"><a href="https://www.mafate.tel">Téléphonie</a></li>
                    <li className="top-links-item"><a href="https://www.mafate.menu">Img Generator</a></li>
                    <li className="top-links-item"><a href="https://www.mafate.academy">Academy / Formations</a></li>
                  </ul>
                </li>
                */}
              </ul>
            </div>

          </div>

          <div className="col-12 col-md-auto dark">

            <ul id="top-social">
              <li><a href="https://facebook.com/mafate.menu" className="si-facebook" target="_blank"><span className="ts-icon"><i className="icon-facebook"></i></span><span className="ts-text">Facebook</span></a></li>
              <li><a href="https://instagram.com/mafate.menu" className="si-instagram" target="_blank"><span className="ts-icon"><i className="icon-instagram2"></i></span><span className="ts-text">Instagram</span></a></li>
              {/*<li><a href="https://twitter.com/mafate.menu" className="si-twitter" target="_blank"><span className="ts-icon"><i className="icon-twitter"></i></span><span className="ts-text">Twitter</span></a></li>
              <li><a href="https://www.youtube.com/channel/UCwOlFlxe-2-CrBdGYVVsyNQ" className="si-youtube" target="_blank"><span className="ts-icon"><i className="icon-youtube"></i></span><span className="ts-text">Youtube</span></a></li>
              <li><a href="tel:+33XXXXXXXXX" className="si-call"><span className="ts-icon"><i className="icon-call"></i></span><span className="ts-text">+33 (0)X XX XX XX XX</span></a></li>
              */}
              <li><a href="mailto:hello@mafate.menu" className="si-email3"><span className="ts-icon"><i className="icon-envelope-alt"></i></span><span className="ts-text">{t('hello@mafate.menu')}</span></a></li>
            </ul>

          </div>
        </div>

      </div>
    </div>

    
    <header id="header" className="transparent-header floating-header header-size-md">
      <div id="header-wrap">
        <div className="container">
          <div className="header-row">

            <div id="logo">
              <a href="index.html" className="standard-logo" data-dark-logo="images/logoMafate1500px.png"><img src="images/logoMafateSmall.png" alt="Mafate Logo" /></a>
              <a href="index.html" className="retina-logo" data-dark-logo="images/logoMafateSmall.png"><img src="images/logoMafateSmall.png" alt="Mafate Logo" /></a>
            </div>

            <div className="header-misc">

              
              <a href="#contact" className="button button-rounded ms-3 d-none d-sm-block">{t('Schedule Appointments')}</a>

            </div>

            <div id="primary-menu-trigger">
              <svg className="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>
            </div>

            <nav className="primary-menu with-arrows">

              <ul className="menu-container">
                <li className="menu-item current"><a className="menu-link" href="#"><div>{t('Home')}</div></a></li>
                <li className="menu-item"><a className="menu-link" href="#pricing"><div>{t('Services')}</div></a></li>
                <li className="menu-item"><a className="menu-link" href="#pricing"><div>{t('Pricing')}</div></a></li>
                <li className="menu-item"><a className="menu-link" href="#contact"><div>{t('Contact Us')}</div></a></li>
              </ul>

            </nav>
            
          </div>
        </div>
      </div>
      <div className="header-wrap-clone"></div>
    </header>
    


    <section id="slider" className="slider-element slider-parallax min-vh-60 min-vh-md-100 min-vh-sm-40 include-header">
      <div className="slider-inner" style={{background: '#FFF url(demos/seo/images/hero/hero-1.jpg) center center no-repeat', backgroundSize: 'cover'}}>

        <div className="vertical-middle slider-element-fade">
          <div className="container py-5 pt-sm-0">
            <div className="row pt-5 pt-sm-0">
              <div className="col-lg-7 col-md-8">
                <div className="slider-title">

                  <div className="badge rounded-pill badge-default">mafate.menu</div>
                  <SliderBlock />

                  <a href="#pricing" className="button button-rounded button-large nott ls0">{t('Pricing')}</a>
                  <a href="#contact" className="button button-rounded button-large button-light text-dark bg-white border nott ls0">{t('Free Online Appointment')}</a>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="video-wrap h-100 d-block d-lg-none">
          <div className="video-overlay" style={{background: 'rgba(255,255,255,0.85)'}}></div>
        </div>

      </div>
    </section>

    <section id="content">

      <div className="content-wrap pt-0 pb-0">

        
        
        <div id="pricing" className="section m-0 pb-sm-5 pt-sm-5" style={{background: 'url(demos/seo/images/sections/4.png) no-repeat center top', backgroundSize: 'cover', padding: '140px 0 0'}}>
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-12">
                <div className="heading-block border-bottom-0 bottommargin-sm">
                  <div className="badge rounded-pill badge-default">{t('Pricing')}</div>
                  <h3 className="nott ls0">{t('PricingBlockTitle')}</h3>
                </div>
                <p>{t('PricingBlockLine1')}<br />{t('PricingBlockLine2')}</p>
              </div>
                <div className="w-100"></div>
                <div className="pricing-tenure-switcher d-flex align-items-center mb-4 mb-sm-0 position-relative" data-container="#pricing-switch">
                <div className="col-5 col-lg-6" style={{textAlign:'right'}}>
                    <span className="pts-left fw-bold text-muted">{t('Pro')}</span>
                </div>
                <div className="col-2 col-lg-1">
                    <div className="pts-switcher mx-3" style={{width: '60px', marginRight: 'auto !important', marginLeft: 'auto !important'}}>
                      <div className="switch">
                        <input id="switch-toggle-pricing-tenure" className="switch-toggle switch-toggle-round" type="checkbox" />
                        <label htmlFor="switch-toggle-pricing-tenure" className="mb-0"></label>
                      </div>
                    </div>
                </div>
                <div className="col-5">
                    <span className="pts-right fw-bold text-muted">{t('PREMIUM')}</span>
                </div>
              </div>

              <div className="col-lg-12">
                <div id="section-pricing" className="page-section p-0 m-0">

                  <div id="pricing-switch" className="pricing row align-items-end g-0 col-mb-50 mb-4">

                    <div className="col-md-6 col-sm-12 pb-sm-0">

                      <div className="pricing-box m-3">
                        <div className="pricing-title">
                          <img className="mb-2 bg-transparent rounded-0" src="demos/seo/images/icons/seo.svg" alt="Pricing Icon" width="60" />
                          <h3>{t('Site Internet')} <span className="pts-switch-content-right d-inline"><span className="badge badge-primary badge-premium">{t('PREMIUM')}</span></span></h3>
                          <span>{t('Responsive Website')}</span>
                        </div>
                        <div className="pricing-price">
                          <span class="price-tenure">{t('from')}</span>
                          <div className="pts-switch-content-left">{prices.professional.flyer.regular}<span className="price-unit">{currency} HT</span><span className="price-tenure"></span></div>
                          <div className="pts-switch-content-right">{prices.premium.flyer.regular}<span className="price-unit">{currency} HT</span><span className="price-tenure"></span></div>
                        </div>
                        <div className="pricing-features border-0 bg-transparent">
                          <ul>

                            <li class="pts-switch-content-left"><i className="icon-check-circle color me-2"></i><strong>1</strong> Proposition</li>
                            <li class="pts-switch-content-right"><i className="icon-check-circle color me-2"></i><strong>2</strong> Propositions</li>
                            <li class="pts-switch-content-left"><i className="icon-check-circle color me-2"></i>Jusqu'à <strong>2</strong> corrections</li>
                            <li class="pts-switch-content-right"><i className="icon-check-circle color me-2"></i>Jusqu'à <strong>5</strong> corrections</li>
                            <li class="pts-switch-content-right"><i className="icon-check-circle color me-2"></i>Délai <strong>4 jours</strong></li>
                            <li class="pts-switch-content-left"><i className="icon-check-circle color me-2"></i>Délai <strong>7 jours</strong></li>
                            <li><i className="icon-check-circle color me-2"></i>Formats <strong>PDF, SVG, PNG</strong></li>

                          <li><i className="icon-check-circle me-2"></i><span className="badge badge-primary badge-premium">+ 100 €</span> URGENT <strong> = Proposition en 24h</strong></li>
                         
                          </ul>
                        </div>
                        <div className="pricing-action">
                          
                        </div>
                      </div>
                      

                    </div>

                    <div className="col-md-6 col-sm-12 pb-sm-0">

                      <div className="pricing-box m-3">
                        <div className="pricing-title">
                          <img className="mb-2 bg-transparent rounded-0" src="demos/seo/images/icons/seo.svg" alt="Pricing Icon" width="60" />
                          <h3>{t('Application Mobile')} <span className="pts-switch-content-right d-inline"><span className="badge badge-primary badge-premium">{t('PREMIUM')}</span></span></h3>
                          <span>{t('iOS (iPhone/iPad) & Android')}</span>
                        </div>
                        <div className="pricing-price">
                          <span class="price-tenure">{t('from')}</span>
                          <div className="pts-switch-content-left">{prices.professional.logo.regular}<span className="price-unit">{currency} HT</span><span className="price-tenure"></span></div>
                          <div className="pts-switch-content-right">{prices.premium.logo.regular}<span className="price-unit">{currency} HT</span><span className="price-tenure"></span></div>
                        </div>
                        <div className="pricing-features border-0 bg-transparent">
                          <ul>

                            

                            <li class="pts-switch-content-left"><i className="icon-check-circle color me-2"></i><strong>1</strong> Proposition</li>
                            <li class="pts-switch-content-right"><i className="icon-check-circle color me-2"></i><strong>2</strong> Propositions</li>
                            <li class="pts-switch-content-left"><i className="icon-check-circle color me-2"></i>Jusqu'à <strong>2</strong> corrections</li>
                            <li class="pts-switch-content-right"><i className="icon-check-circle color me-2"></i>Jusqu'à <strong>5</strong> corrections</li>
                            <li class="pts-switch-content-right"><i className="icon-check-circle color me-2"></i>Délai <strong>4 jours</strong></li>
                            <li class="pts-switch-content-left"><i className="icon-check-circle color me-2"></i>Délai <strong>7 jours</strong></li>
                            <li><i className="icon-check-circle color me-2"></i>Formats <strong>PDF, SVG, PNG</strong></li>
                            

                          <li><i className="icon-check-circle me-2"></i><span className="badge badge-primary badge-premium">+ 250 €</span> URGENT <strong> = Proposition en 24h</strong></li>
                         


                          </ul>
                        </div>
                        <div className="pricing-action">
                          
                        </div>
                      </div>
                      

                    </div>

                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>


        <div id="contact" className="section m-0 pb-sm-5 pt-sm-5" style={{background: 'url(demos/seo/images/sections/1.jpg) no-repeat center center', backgroundSize: 'cover', padding: '100px 0'}}>
          <div className="container">
            <div className="row justify-content-between align-items-center">

              <div className="col-md-4">
                <div className="heading-block border-bottom-0 bottommargin-sm">
                  <div className="badge rounded-pill badge-default">{t('Contact Us')}</div>
                  <h3 className="nott ls0">{t('ContactBlockLine1')}</h3>
                </div>
                <p>{t('ContactBlockLine2')}.</p>

                <div className="widget clearfix mb-4">

                  <div className="row clearfix">
                    <div className="col-12 mb-4">
                      <div className="feature-box fbox-plain fbox-sm align-items-center">
                        <div className="fbox-icon">
                          <i className="icon-envelope text-dark"></i>
                        </div>
                        <div className="fbox-content">
                          
                          <span className="text-muted">Email :</span><br />
                          <h3 className="nott ls0 fw-semibold">{t('hello@mafate.menu')}</h3>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>


              </div>

              {/*
              <div className="col-lg-6 col-md-8">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h4 className="mb-3">{t('Your details')}</h4>
                    <div className="form-widget">
                      <div className="form-result"></div>
                      <form className="row mb-0" id="template-contactform" name="template-contactform" action="include/form.php" method="post">
                        <div className="col-6 form-group mb-3">
                          <label htmlFor="template-contactform-name">{t('Name')}*</label>
                          <input type="text" id="template-contactform-name" name="template-contactform-name" className="form-control input-sm required" value="" />
                        </div>
                        <div className="col-6 form-group mb-3">
                          <label htmlFor="template-contactform-company">{t('Company')}</label>
                          <input type="text" id="template-contactform-company" name="template-contactform-company" className="form-control input-sm" value="" />
                        </div>
                        <div className="col-6 form-group mb-3">
                          <label htmlFor="template-contactform-email">{t('Email')}*</label>
                          <input type="email" id="template-contactform-email" name="template-contactform-email" className="form-control input-sm required" value="" />
                        </div>
                        <div className="col-6 form-group mb-3">
                          <label htmlFor="template-contactform-phone">{t('Phone')}*</label>
                          <input type="email" id="template-contactform-phone" name="template-contactform-phone" className="form-control input-sm required" value="" />
                        </div>
                        <div className="col-12 form-group mb-4">
                          <label htmlFor="template-contactform-message">{t('Your Message')}*</label>
                          <textarea type="text" id="template-contactform-message" name="template-contactform-website" className="form-control input-sm required" value=""></textarea>
                        </div>
                        <div className="col-12 form-group d-none">
                          <input type="text" id="template-contactform-botcheck" name="template-contactform-botcheck" value="" className="sm-form-control" />
                        </div>
                        <div className="col-12 form-group mb-0">
                          <button className="button button-rounded w-100 nott ls0 m-0" type="submit" id="template-contactform-submit" name="template-contactform-submit" value="submit">{t('Send')}</button>
                        </div>
                        <input type="hidden" name="prefix" value="template-contactform-" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              */}

              <div className="col-lg-6 col-md-8">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h4 className="mb-3">{t('Booking an online appointment')}</h4>
                    <div className="form-widget">
                      <div className="form-result"></div>
                      <form className="row mb-0" id="template-contactform" name="template-contactform" action="include/form.php" method="post">
                        
                        <div className="col-12 form-group mb-0">


                         <div 
                          className="calendly-inline-widget"
                          data-url="https://calendly.com/mafate/rdv?hide_gdpr_banner=1&primary_color=ffbe49"
                          style={{ minWidth: '320px', height: '700px' }} />


                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
    </section>

    <footer id="footer" className="border-0 bg-white">
      <div className="container">

        <div className="footer-widgets-wrap pb-5 clearfix">

          <div className="row col-mb-50">
            <div className="col-md-8">

              <div className="widget clearfix">

                <img src="images/logoMafateSmall.png" alt="Footer Logo" className="alignleft" style={{width:'50%', maxWidth:'250px', marginTop: '-6px', paddingRight: '18px', borderRight: '1px solid #DDD'}} />

                <p>
                <Trans
                  i18nKey="accroche_bas"
                  defaults="..." // optional defaultValue
                  values={{ arg1: '...',arg2: '...'}}
                  components={{ italic: <i />, bold: <strong /> }}
                />
                </p>

                {/*
                <div className="line line-sm"></div>
                
                <div className="row">
                  <div className="col-lg-3 col-6 bottommargin-sm widget_links">
                    <ul>
                      <li><a href="#">Applications Mobiles</a></li>
                      <li><a href="#">Site Internet</a></li>
                      <li><a href="#">Nom de Domaine</a></li>
                      <li><a href="#">Emails professionnels</a></li>
                    </ul>
                  </div>

                  <div className="col-lg-3 col-6 bottommargin-sm widget_links">
                    <ul>
                      <li><a href="#">Société de services</a></li>
                      <li><a href="#">Expert-Comptable</a></li>
                      <li><a href="#">Avocat</a></li>
                      <li><a href="#">Restaurants</a></li>
                    </ul>
                  </div>

                  <div className="col-lg-3 col-6 bottommargin-sm widget_links">
                    <ul>
                      <li><a href="#">Chanteurs/Groupe</a></li>
                      <li><a href="#">Artistes</a></li>
                      <li><a href="#">Événementiel</a></li>
                      <li><a href="#">Magazine</a></li>
                    </ul>
                  </div>

                  <div className="col-lg-3 col-6 bottommargin-sm widget_links">
                    <ul>
                      <li><a href="#">Coach / Formateur</a></li>
                      <li><a href="#">Photographes</a></li>
                      <li><a href="#">Radio / Podcasteur</a></li>
                      <li><a href="#">Influenceurs</a></li>
                    </ul>
                  </div>
                </div>
                */}

              </div>

            </div>

            <div className="col-md-4">
              {/*
              <div className="widget clearfix">

                <div className="row clearfix">
                  <div className="col-12 mt-4">
                    <div className="feature-box fbox-plain fbox-sm align-items-center">
                      <div className="fbox-icon">
                        <i className="icon-envelope text-dark"></i>
                      </div>
                      <div className="fbox-content">
                        <span className="text-muted">Email :</span><br />
                        <h3 className="nott ls0 fw-semibold">{t('hello@mafate.menu')}</h3>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            */}

            {/*
              <div className="widget subscribe-widget clearfix">
                <h5><strong>{t('Subscribe')}</strong> {t('to our newsletter')}</h5>
                <div className="widget-subscribe-form-result"></div>
                <form id="widget-subscribe-form" action="https://api.mafate.co/v1.0/" method="post" className="mb-0">
                  <div className="input-group mx-auto">
                    <div className="input-group-text bg-transparent"><i className="icon-email2"></i></div>
                    <input type="email" id="widget-subscribe-form-email" name="widget-subscribe-form-email" className="form-control required email" placeholder="Email" />
                    <button className="btn btn-success button button-color nott ls0 m-0" type="submit">{t('btnSubscribe')}</button>
                  </div>
                </form>
              </div>
            */}

              <div className="widget">

                <div className="row col-mb-30">
                  <div className="col-lg-6 col-md-12 col-6">
                    <a href="https://facebook.com/mafate.menu" target="_blank" className="social-icon si-dark si-colored si-facebook mb-0 marginRight10">
                      <i className="icon-facebook"></i>
                      <i className="icon-facebook"></i>
                    </a>
                    <a href="https://facebook.com/mafate.menu" target="_blank" className="text-dark visible-*-block mt-3"><small style={{display: 'block', marginTop:'3px'}}><strong>{t('Follow Us')}</strong><br/>{t('On Facebook')}</small></a>
                  </div>
                  <div className="col-lg-6 col-md-12 col-6">
                    <a href="https://www.instagram.com/mafate.menu" target="_blank" className="social-icon si-dark si-colored si-rss mb-0" style={{marginRight: '10px'}}>
                      <i className="icon-instagram2"></i>
                      <i className="icon-instagram2"></i>
                    </a>
                    <a href="https://www.instagram.com/mafate.menu" target="_blank" className="text-dark"><small style={{display: 'block', marginTop: '3px'}}><strong>{t('Follow Us')}</strong><br/>{t('On Instagram')}</small></a>
                  </div>
                
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div id="copyrights" style={{background: 'url(demos/seo/images/hero/footer.svg) no-repeat top center', backgroundSize: 'cover', paddingTop: '70px'}}>
        <div className="container clearfix">

          <div className="row justify-content-between col-mb-30">
            <div className="col-12 col-lg-auto text-center text-lg-start">
              &copy; 2023 mafate.menu<br />
              <div className="copyright-links">{/*<a href="#">{t('Terms of Use')}</a> / <a href="#">{t('Privacy Policy')}</a>*/}</div>
            </div>

            <div className="col-12 col-lg-auto text-center text-lg-end">
              <div className="copyrights-menu copyright-links clearfix">
                <a href="#">{t('Home')}</a>/<a href="#services">{t('Services')}</a>/<a href="#pricing">{t('Pricing')}</a>/<a href="#contact">Contact</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </footer>

  </div>
  </>
  );
}

export default App;
